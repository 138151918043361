import React from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import { FiPlus } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';

const AddAssetsModal = ({ show, allUserLocations, onHide, allAssetsType, handleChange, step, setStep, data, addAssignedField, handleAssignChange, removeAssignedField, assigned, handleSubmit, dynamicInput, handleDynamicInputChange, addDynamicInputField, removeDynamicInputField, setAssignUser, assignUser }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="">
        <div className='d-flex justify-content-center align-items-center flex-column px-5'>
            <p style={{fontSize: "32px", fontWeight: "600", marginBottom: "0px"}}>Add Assets</p>
            <p style={{color: "#475467", fontWeight: "500"}}>Add asset to the pool of the assets in the company.</p>
            <div className='d-flex justify-content-center align-items-center my-4'>
                <p style={{color: "#007DCC", fontWeight: "500", marginBottom: "0px"}}>Asset Info</p>
                <span style={{width: "60px", borderTop: step > 1 ? "2px solid #007DCC" : "2px solid #D9D9D9", margin: "0px 20px"}}></span>
                <p style={{ color: step > 1 ? "#007DCC": "#475467", marginBottom: "0px", fontWeight: step > 1 ? "500" : "400"}}>Purchase Info</p>
                <span style={{width: "60px", borderTop: step > 2 ? "2px solid #007DCC" : "2px solid #D9D9D9", margin: "0px 20px"}}></span>
                <p style={{color: step > 2 ? "#007DCC": "#475467", marginBottom: "0px", fontWeight: step > 2 ? "500" : "400"}}>User Assignment</p>
            </div>
            {step == 1 && <>
            <div className='d-flex justify-content-between mt-1' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Device type <span style={{color: "#E02D3C"}}>*</span></p>
                <select required value={data?.asset_type_name} name='asset_type_name' onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}}>
                    <option></option>
                    {allAssetsType?.data?.map((each, index) => (
                <option key={index} value={each?.name}>
                  {each?.name}
                </option>
              ))}
                </select>
                </div>
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Device name <span style={{color: "#E02D3C"}}>*</span></p>
                <input type="text" value={data?.description} required name='description' onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div>
            <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Tag/code number <span style={{color: "#E02D3C"}}>*</span></p>
                <input type="text" placeholder='NGSL/LAG/0000' name="asset_tag" value={data?.asset_tag} onChange={handleChange} required style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
                {/* <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> Storage</p>
                <input type="text" name="storage" value={data?.storage} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>  */}
            </div>
            {/* <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Display</p>
                <input type="text" name="display" value={data?.display} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> Graphics</p>
                <input type="text" name="graphics" value={data?.graphics} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div>
            <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Operating system</p>
                <input type="text" name="operatingSystem" value={data?.operatingSystem} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> Battery life</p>
                <input type="text" name="batteryLife" value={data?.batteryLife} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div> */}
            </>}

            {step == 2 && <>
              <div className='d-flex justify-content-between mt-1' style={{width: "100%"}}>
              <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Purchase cost <span style={{color: "#E02D3C"}}>*</span></p>
                <input type="text" value={data?.purchase_cost} required name='purchase_cost' onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Purchase date <span style={{color: "#E02D3C"}}>*</span></p>
                <input type="date" value={data?.purchase_date} required name='purchase_date' onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div>
                {dynamicInput?.map((each, index) => <div style={{width: "100%"}}>
                <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Field</p>
                <input type="text" name="name" placeholder='e.g Router ports/OS/Screen size...' value={each?.name || ""} onChange={e => handleDynamicInputChange(index, e)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> Value</p>
                <input type="text" name="value" placeholder='e.g No of ports/Windows 11/OS 11...' value={each?.value || ""} onChange={e => handleDynamicInputChange(index, e)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div>
            <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
            {/* {index == dynamicInput?.length - 1 && <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "blue",
              }}
              onClick={() => {
                addDynamicInputField()
              }}
            >
              Add another field
            </button>} */}

            <div></div>

            {dynamicInput?.length >= 1 && <button
              className="rounded px-1"
              style={{
                color: "red",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "20px",
                // paddingBottom: "5px"
              }}
              onClick={() => {
                removeDynamicInputField(index)
              }}
            >
              <RiDeleteBin6Line />
            </button>}
            </div>
            </div>)}
            <div className='d-flex justify-content-between mt-1' style={{width: "100%"}}>
            <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Assign to user <span style={{color: "#E02D3C"}}>*</span></p>
                <select required value={assignUser} name='asset_type_name' onChange={(e)=> setAssignUser(e.target.value)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}}>
                    <option></option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                </div>
            </div>
            
                {/* <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Brand</p>
                <input type="text" name="brand" value={data?.brand} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> Model</p>
                <input type="text" name="model" value={data?.model} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div> */}
            {/* <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Processor</p>
                <input type="text" name="processor" value={data?.processor} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> Ram</p>
                <input type="text" name="ram" value={data?.ram} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div>
            <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Weight (kg)</p>
                <input type="text" name="weight" value={data?.weight} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Price</p>
                <input type="text" name="price" value={data?.price} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
            </div>
            <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Warranty (years)</p>
                <input type="text" name="warranty" value={data?.warranty} onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
            </div> */}
            </>}

            {step == 3 && 
            <>
            {assigned?.map((each, index) => <div style={{width: "100%"}}>
                <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Full name</p>
                <input type="text" name="full_name" value={each?.full_name || ""} onChange={e => handleAssignChange(index, e)} required style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div>
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> Staff Id</p>
                <input type="text" name="reg_id" value={each?.reg_id || ""} onChange={e => handleAssignChange(index, e)} required placeholder='NGSL0000' style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div>
            <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                {/* <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Location</p>
                <input type="text" name="staff_location" required placeholder='Marina' value={each?.staff_location || ""} onChange={e => handleAssignChange(index, e)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> */}
                <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Staff location <span style={{color: "#E02D3C"}}>*</span></p>
                <select required value={each?.staff_location} name='staff_location' onChange={e => handleAssignChange(index, e)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}}>
                    <option></option>
                    {allUserLocations?.map((item, idx) => (
                <option key={idx} value={item?.key}>
                  {item?.name}
                </option>
              ))}
                </select>
                </div>
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> State</p>
                <input type="text" name="state" required placeholder='Lagos' value={each?.state || ""} onChange={e => handleAssignChange(index, e)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div>
            <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
                {/* <div className='d-flex flex-column justify-content-start'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Location</p>
                <input type="text" name="staff_location" required placeholder='Marina' value={each?.staff_location || ""} onChange={e => handleAssignChange(index, e)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> */}
                <div className='d-flex flex-column justify-content-start '>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}> Department</p>
                <input type="text" name="department" required placeholder='IT' value={each?.department || ""} onChange={e => handleAssignChange(index, e)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}} />
                </div> 
            </div>
            <div className='d-flex justify-content-between mt-2' style={{width: "100%"}}>
            {/* {index == assigned?.length - 1 && <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "blue",
              }}
              onClick={() => {
                addAssignedField()
              }}
            >
              Add another user
            </button>} */}

            <div></div>

            {assigned?.length > 1 && <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "red",
              }}
              onClick={() => {
                removeAssignedField(index)
              }}
            >
              Delete
            </button>}
            </div>
            </div>)}
            </>
            }
{step == 2 && <div className='d-flex justify-content-start' style={{width: "100%"}}>
<button
              className="rounded py-2"
              style={{
                color: "#0075C9",
                border: "none",
                backgroundColor: "transparent",
                marginTop: "10px",
                fontWeight: "bold"
              }}
              onClick={() => {
                addDynamicInputField()
              }}
            >
              <FiPlus size={20} /> Add another field
            </button>
            </div>}
            
            <div className='d-flex justify-content-end mt-4' style={{width: "100%"}}>
            {step > 1 && <button
              className="rounded py-2 px-3"
              style={{
                color: "#414141",
                border: "none",
                backgroundColor: "transparent",
                marginRight: "20px",
                border: "1px solid #D0D5DD",
                fontWeight: "500"
              }}
              onClick={() => setStep(step - 1)}
            >
              Previous
            </button>}
            <div></div>
            <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
              }}
              onClick={(e) => {
                // console.log("step ", step);
                if (step == 3) {
                  handleSubmit(e)
                }
                else{
                  if(step == 2 && assignUser == "no"){
                    handleSubmit(e)
                  }
                  else if(step == 2 && assignUser == "yes"){
                    setStep(step + 1)
                  }
                  else if(step == 3){
                    handleSubmit(e)
                  }
                  else if(step == 1 && assignUser == null){
                    setStep(step + 1)
                  }
                  else if(step == 1) {
                    setStep(step + 1)
                  }
                }
              }}
            >
              {step == 2 && assignUser == "yes" ? "Next" : step == 1 && assignUser == null ? "Next" : step == 1? "Next" : "Submit"}
            </button>
            </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddAssetsModal