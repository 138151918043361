import React from 'react'
import { Modal, Spinner } from 'react-bootstrap';

const EditPurchaseDetails = ({ show, onHide, handleEditPurchase, handleChange, step, setStep, arrayData, addAssignedField, handleAssignChange, removeAssignedField, assigned, handleSubmit, dynamicInput, handleDynamicInputChange, addDynamicInputField, removeDynamicInputField }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="">
        <div className='d-flex justify-content-center align-items-center flex-column px-5'>
            <p style={{fontSize: "32px", fontWeight: "600", marginBottom: "0px"}}>Edit Purchase Details</p>
            <p style={{color: "#475467", fontWeight: "500"}}>Edit purchase details of the asset in the company.</p>

            <>
            {arrayData?.map((field, index) => (
        <div key={index} className="d-flex justify-content-between mt-2" style={{ width: "100%" }}>
          <div className="d-flex flex-column justify-content-start">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>Name</p>
            <input
              type="text"
              name="name"
              value={field.key || ""}
              onChange={e => handleChange(index, "key", e.target.value)}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            />
          </div>
          <div className="d-flex flex-column justify-content-start">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>Value</p>
            <input
              type="text"
              name="value"
              value={field.value || ""}
              onChange={e => handleChange(index, "value", e.target.value)}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            />
          </div>
        </div>
      ))}
                
            </>

            <div className='d-flex justify-content-between mt-4' style={{width: "100%"}}>
             <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "red",
              }}
              onClick={onHide}
            >
              Close
            </button>
            <div></div>
            <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
              }}
              onClick={handleEditPurchase}
            >
              Submit
            </button>
            </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditPurchaseDetails