import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import pcFrame from '../assets/pcFrame.png'
import line from '../assets/linepattern.png'
import lineImage from '../assets/Line.png'
import logo from '../assets/logo_sanlam.png'
import LoginImage from '../assets/LoginImage.png'
import { FaEye, FaEyeSlash, FaSpinner } from 'react-icons/fa';
import { notify } from '../components/actions/CommonActions';
import { Button, Col, Form, Row } from 'react-bootstrap';
import LoadingModal from '../components/modals/LoadingModal';
import { loginUserApi, setPasswordUserApi } from '../services';

const ActivateUser = () => {
const [login, setLogin] = useState({ password: "", newPassword: "" });
  const [isLoggingin, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState("")
    // console.log('id ', id);
    const location = useLocation();
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const tokenParam = searchParams.get("token");
      setId(tokenParam)
      
    }, [location]);

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  async function loginAcc() {
    setIsLoggingIn(true);
    if(login.password == login.newPassword){
    const sentData = {
        password: login?.password
    }
    try {
        const result = await setPasswordUserApi(sentData, id);
        console.log('log result', result);
        localStorage.setItem("token", result.access_token)
        
  
        const { data } = result;
        if (data) {
          setIsLoggingIn(false);
          navigate("/");
        // navigate("/dashboard")
        }
    } catch (error) {
      notify("danger", "OOPS", error?.response?.data?.message);
    }
}
else{
    notify("danger", "OOPS", 'Passwords do not match');
}
    setIsLoggingIn(false);
  }

  return (
    <Row style={{ height: "100vh" }}>
      <Col
        xs={6}
        style={{
          backgroundColor: "#007dcc",
          position: "relative",
          color: "#fff",
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'cover'
        }}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
      </Col>
      <Col
        xs={6}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
        <div style={{ width: "65%", marginBottom: "150px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "150px", marginBottom: "120px" }}
          />
          <h3>Set Password</h3>
          <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
            Set your new password
          </p>
          <Form className="d-flex flex-column gap-3" onSubmit={loginAcc}>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control name="password" type="password" onChange={handleLoginChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Re-enter password</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                onChange={handleLoginChange}
              />
            </Form.Group>
            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isLoggingin}
              onClick={(e)=>{
                e.preventDefault()
                loginAcc()
              }}
            >
              {isLoggingin ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </div>
      </Col>
      <LoadingModal show={isLoggingin} />
    </Row>
  );
};

export default ActivateUser