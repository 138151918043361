import moment from 'moment'
import React from 'react'
import { GoPencil } from 'react-icons/go'

const AssetInfo = ({gottenData, setEditModalShow}) => {
  return (
    <>
    <div>
          <p style={{marginBottom: "0px", backgroundColor: "#F2F4F7", fontWeight: "500", fontSize: "23px", padding: "10px"}}>Asset info</p>
          <div style={{border: "1px solid #EAECF0", padding: "10px", minHeight: "20vh"}}>
            <div style={{width: "800px", display: "flex", justifyContent: "space-between"}}>
                <p style={{color: "#475467"}}>Device type</p>
                <p style={{width: "300px", color: "black", fontWeight: "500"}}>{gottenData?.metadata?.metadataType ?? gottenData?.device_type?.key}</p>
            </div>
            <div style={{width: "800px", display: "flex", justifyContent: "space-between"}}>
                <p style={{color: "#475467"}}>Device name</p>
                <p style={{width: "300px", color: "black", fontWeight: "500"}}>{gottenData?.description}</p>
            </div>
            <div style={{width: "800px", display: "flex", justifyContent: "space-between"}}>
                <p style={{color: "#475467"}}>Life Cycle</p>
                <p style={{width: "300px", color: "black", fontWeight: "500"}}>{gottenData?.lifeCycle}</p>
            </div>
            
          </div>
          </div>
          <div style={{marginTop: "10px"}}>
          <div style={{marginBottom: "0px", backgroundColor: "#F2F4F7", padding: "10px", display: "flex", justifyContent: "space-between"}}>
          <p style={{marginBottom: "0px", fontWeight: "500", fontSize: "23px"}}>Purchase info</p>
          <button
              className="rounded py-2 px-3"
              style={{
                color: "#007DCC",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                fontWeight: "bold"
              }}
              onClick={() => setEditModalShow(true)}
            >
              <GoPencil size={20} /> Edit details
            </button>
          </div>
          <div style={{border: "1px solid #EAECF0", padding: "10px", minHeight: "20vh"}}>
          <div style={{width: "800px", display: "flex", justifyContent: "space-between"}}>
                <p style={{color: "#475467"}}>Purchase date</p>
                <p style={{width: "300px", color: "black", fontWeight: "500"}}>{moment(gottenData?.purchaseDate).format("MMM DD, YYYY")}</p>
            </div>
  {Object.entries(gottenData?.metadata?.dynamicFields || {}).map(([key, value]) => (
    <div key={key} style={{width: "800px", display: "flex", justifyContent: "space-between"}}>
      <p style={{color: "#475467"}}>{key?.replace("_", " ").replace(/\b\w/g, char => char.toUpperCase())}</p>
      <div style={{width: "300px", color: "black", fontWeight: "500"}}>
        {typeof value === 'object' && value !== null ? (
          <div>
            {Object.entries(value).map(([subKey, subValue]) => (
              <p key={subKey}>{subKey}: {subValue}</p>
            ))}
          </div>
        ) : (
          <p>{value}</p>
        )}
      </div>
    </div>
  ))}
</div>
          </div>
          </>
  )
}

export default AssetInfo