import moment from 'moment'
import React from 'react'
import { GoDotFill } from 'react-icons/go'
import { IoChevronDownSharp, IoChevronUpOutline } from 'react-icons/io5'
import { MdAdd } from 'react-icons/md'

const EditHistory = ({ gottenData }) => {
    return (
        <>
            <div style={{ marginBottom: "0px", backgroundColor: "#F2F4F7", padding: "10px", display: "flex", justifyContent: "space-between" }}>
                <p style={{ marginBottom: "0px", fontWeight: "500", fontSize: "23px" }}>Edit History</p>
                <div></div>
            </div>
            <div style={{ border: "1px solid #EAECF0", padding: "10px", minHeight: "70vh" }}>
               {gottenData?.edit_history?.map((each, index) => <div key={index} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "10px" }}>
                    
                    <p style={{ color: "#667085", marginBottom: "0px", marginLeft: "10px" }}> <GoDotFill /> “Edited {each?.field} from <strong style={{ color: "black" }}>
                         {typeof JSON.parse(each?.old_changes) === 'object' ? (
                        Object.entries(JSON.parse(each?.old_changes))
                            .map(([key, value]) => `${key}: ${value}`)
                            .join(', ')
                    ) : (
                        <strong style={{ color: "black" }}>{JSON.parse(each?.old_changes).replace(/^"|"$/g, '')}</strong> // Remove quotes if it's a simple string
                    )} 
                    {" "}</strong> to <strong style={{ color: "black" }}>
                         {typeof JSON.parse(each?.new_changes) === 'object' ? (
                        Object.entries(JSON.parse(each?.new_changes))
                            .map(([key, value]) => `${key}: ${value}`)
                            .join(', ')
                    ) : (
                        <strong style={{ color: "black" }}>{JSON.parse(each?.new_changes).replace(/^"|"$/g, '')}</strong> // Remove quotes if it's a simple string
                    )} 
                    {" "}</strong> by <strong style={{ color: "black" }}> Ige.samuel@sanlam.com.ng</strong> at {moment(each?.createdAt).format("YYYY-MM-DD, h:mmA")}”</p>
                </div>)}
            </div>
        </>
    )
}

export default EditHistory