import React from 'react'
import { Modal, Spinner } from 'react-bootstrap';

const ReassignAssetModal = ({ show, onHide, allUserLocations, handleChange, handleSubmit}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="">
        <form onSubmit={handleSubmit} className='d-flex justify-content-center align-items-center flex-column px-5'>
            <p style={{fontSize: "32px", fontWeight: "600", marginBottom: "0px"}}>Reassign asset.</p>
            <p style={{color: "#475467", fontWeight: "500"}}>To reassign asset kindly fill the form below.</p>

        <div className="d-flex align-items-center flex-column  mt-2" style={{ width: "100%" }}>
          <div className="d-flex flex-column justify-content-start">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>Full name</p>
            <input
              type="text"
              name="full_name"
              required
              placeholder='Emmanuel Kingsley'
              onChange={handleChange}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            />
          </div>
          <div className="d-flex flex-column justify-content-start">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}> Department</p>
            <input
              type="text"
              name="department"
              required
              placeholder='IT'
              onChange={handleChange}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            />
          </div>
          <div className="d-flex flex-column justify-content-start mt-2">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>Staff Id</p>
            <input
              type="text"
              name="reg_id"
              required
              placeholder='NGSL0000'
              onChange={handleChange}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            />
          </div>
          <div className="d-flex flex-column justify-content-start mt-2">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>Staff Location</p>
            {/* <input
              type="text"
              name="staff_location"
              required
              placeholder='Marina'
              onChange={handleChange}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            /> */}
            <select required name='staff_location' onChange={handleChange} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}}>
                    <option></option>
                    {allUserLocations?.map((item, idx) => (
                <option key={idx} value={item?.key}>
                  {item?.name}
                </option>
              ))}
                </select>
          </div>
          <div className="d-flex flex-column justify-content-start mt-2">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>State</p>
            <input
              type="text"
              name="state"
              required
              placeholder='Lagos'
              onChange={handleChange}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            />
          </div>
        </div>

            <div className='d-flex justify-content-between mt-4' style={{width: "100%"}}>
             <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "red",
              }}
              onClick={onHide}
            >
              Close
            </button>
            <div></div>
            <button
            type="submit"
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
              }}
            >
              Submit
            </button>
            </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ReassignAssetModal