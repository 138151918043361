import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo_sanlam.png'
import LoginImage from '../assets/LoginImage.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { notify } from '../components/actions/CommonActions';
import { Button, Col, Form, Row } from 'react-bootstrap';
import LoadingModal from '../components/modals/LoadingModal';
import { changePasswordApi, loginUserApi } from '../services';

const ChangePassword = () => {
  const [login, setLogin] = useState({ password: "", confirmPassword: "" });
  const [isLoggingin, setIsLoggingIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  async function loginAcc(e) {
    e.preventDefault();
    if (login.password !== login.confirmPassword) {
      notify("danger", "OOPS", "Passwords do not match.");
      return;
    }
    const userEmail = await localStorage.getItem("userEmail")
    const sessionId = await localStorage.getItem("forgotPasswordSessionId")

    const sentData = {
        email: userEmail,
        new_password: login?.password,
        session_id: sessionId
    }

    setIsLoggingIn(true);
    try {
      const result = await changePasswordApi(sentData);
      console.log('log result chnage password', result);
        setIsLoggingIn(false);
        navigate("/");
    } catch (error) {
      notify("danger", "OOPS", error?.response?.data?.message);
    }
    setIsLoggingIn(false);
  }

  return (
    <Row style={{ height: "100vh" }}>
      <Col
        xs={6}
        style={{
          backgroundColor: "#007dcc",
          position: "relative",
          color: "#fff",
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'cover'
        }}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      />
      <Col
        xs={6}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
        <div style={{ width: "65%", marginBottom: "150px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "150px", marginBottom: "120px" }}
          />
          <h3>Login</h3>
          <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
            Enter your credentials
          </p>
          <Form className="d-flex flex-column gap-3" onSubmit={loginAcc}>

            {/* Password Field */}
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleLoginChange}
                />
                <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer", marginLeft: "8px" }}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </Form.Group>

            {/* Confirm Password Field */}
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  onChange={handleLoginChange}
                />
                <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: "pointer", marginLeft: "8px" }}>
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </Form.Group>


            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isLoggingin}
            >
              {isLoggingin ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </div>
      </Col>
      <LoadingModal show={isLoggingin} />
    </Row>
  );
};

export default ChangePassword;
