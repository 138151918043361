import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import pcFrame from '../assets/pcFrame.png'
import line from '../assets/linepattern.png'
import lineImage from '../assets/Line.png'
import logo from '../assets/logo_sanlam.png'
import LoginImage from '../assets/LoginImage.png'
import { FaEye, FaEyeSlash, FaSpinner } from 'react-icons/fa';
import { notify } from '../components/actions/CommonActions';
import { Button, Col, Form, Row } from 'react-bootstrap';
import LoadingModal from '../components/modals/LoadingModal';
import { loginUserApi } from '../services';

const Login = () => {
const [login, setLogin] = useState({ email: "", password: "" });
  const [isLoggingin, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  async function loginAcc() {
    setIsLoggingIn(true);
    try {
        const result = await loginUserApi(login);
        console.log('log result', result);
        localStorage.setItem("token", result?.access_token)
        localStorage.setItem("refreshToken", result?.refresh_token)
        
  
        const { data } = result;
        if (data) {
          // Extracting first name from the response data
          const firstName = data?.firstName;
          // console.log('log data', data);
          
  
          localStorage.setItem("userdata", JSON.stringify(data)); // Assuming the JWT token is returned directly
          localStorage.setItem("firstName", firstName); // Storing first name in local storage
          setIsLoggingIn(false);
          navigate("/dashboard");
        // navigate("/dashboard")
        }
    } catch (error) {
      console.log('log error', error);
      notify("danger", "OOPS", error?.response?.data?.message);
    }
    setIsLoggingIn(false);
  }

  return (
    <Row style={{ height: "100vh" }}>
      <Col
        xs={6}
        style={{
          backgroundColor: "#007dcc",
          position: "relative",
          color: "#fff",
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'cover'
        }}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
      </Col>
      <Col
        xs={6}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
        <div style={{ width: "65%", marginBottom: "150px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "150px", marginBottom: "120px" }}
          />
          <h3>Login</h3>
          <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
            Enter your credentials
          </p>
          <Form className="d-flex flex-column gap-3" onSubmit={loginAcc}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" onChange={handleLoginChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={handleLoginChange}
              />
            </Form.Group>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
            <a href="/forgot-password" style={{ color: "#007dcc" }}>
              Forgot password?
              </a>
              </div>
            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isLoggingin}
              onClick={(e)=>{
                e.preventDefault()
                loginAcc()
              }}
            >
              {isLoggingin ? "Logging in..." : "Login"}
            </Button>
          </Form>
        </div>
      </Col>
      <LoadingModal show={isLoggingin} />
    </Row>
  );
};

export default Login