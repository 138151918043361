import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import { AuthContext } from './components/helper/AuthContext';
import 'react-notifications-component/dist/theme.css'
import Login from './pages/login';
import { AppReducer, initialState } from './context/reducer';
import React from 'react';
import ActivateUser from './pages/SetPassword';
import Root from './components/Root';
import Dashboard from './pages/dashboard';
import Assets from './pages/Assets';
import AssetDetails from './pages/AssetDetails';
import Reports from './pages/Reports';
import Users from './pages/users';
import ForgotPassword from './pages/forgotPassword';
import ValidateOtp from './pages/validateOtp';
import ChangePassword from './pages/ChangePassword';

function App() {
  const [state, dispatch] = React.useReducer(AppReducer, initialState);

  const router = createBrowserRouter([
    {
      path: "/*",
      element: <Root />,
      // errorElement: <ErrorPage />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "assets",
          element: <Assets />,
        },
        {
          path: "assets/details/:id",
          element: <AssetDetails />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "users",
          element: <Users />,
        },
      ],
    },
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/validate-otp",
      element: <ValidateOtp />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/activate-user",
      element: <ActivateUser />,
    }
    // {
    //   path: "activate-User/:id",
    //   element: <ActivateUser />,
    // },
  ]);


  return (
    <div className="App">
      <ReactNotifications />
      <AuthContext.Provider
        value={{
          // ...state,
          // ...authActions,
          // ...errorResponseActions,
          // ...formActions,
          // ...commonActions,
        }}
      >
        <RouterProvider router={router} />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
