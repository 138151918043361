import React, { useEffect, useState } from 'react'
import { FaArrowDownLong } from 'react-icons/fa6'
import LoadingModal from '../components/modals/LoadingModal';
import { downloadReport, getAllCountApi, getDashboardMetrics } from '../services';
import { capitalize, notify } from '../components/actions/CommonActions';
import Chart from 'react-apexcharts';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userLoginDetails, setUserLoginDetails] = useState(null)
  const [dashboardData, setDashboardData] = useState([])
  const [loading, setLoading] = useState(false)
  const [metricsRes, setMetricsRes] = useState(null)
  const [donutdata, setDonutData] = useState(null)
  const series = donutdata?.data?.map((item) => item.totalCount) || [];
  const labels = donutdata?.data?.map(
    (item) => `${item.typeName} - ${item.totalCount}`
  ) || [];
  const [optionsBar, setOptionsBar] = useState({
    series: [],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['<1 yr', '1-2 yrs', '2-3 yrs', '3-4 yrs', '4-5 yrs', '5-6 yrs', '6-7 yrs', '7-8 yrs', '8-9 yrs'],
        title: {
            text: 'Years'
        }
    },
    yaxis: {
        title: {
            text: 'Count'
        }
    },
    fill: {
        opacity: 1,
        colors: ['#007DCC', '#002C47']
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + " units";
            }
        }
    }
});

  const getDashboard = async () => {
    // setLoading(true)
    await getDashboardMetrics()
    .then((res)=>{
        // console.log("dashboard metrics ,", res);
        setDashboardData(res?.data?.metrics || []);
        setMetricsRes(res)
        
    })
    .catch((error)=>{
        console.log("error of dashboard metrics", error);
        
    })
    // setLoading(false)
  }

  const getAllCount = async () => {
    // setLoading(true)
    await getAllCountApi()
    .then((res)=>{
        // console.log("all count metrics ,", res);
        setDonutData(res)
    })
    .catch((error)=>{
        console.log("error of dashboard metrics", error);
        
    })
    // setLoading(false)
  }

  useEffect(() => {
    const initiate = async () => {
    const getUserdata = await localStorage.getItem("userdata")
    setUserLoginDetails(JSON.parse(getUserdata));
    setLoading(true)
    await getDashboard()
    await getAllCount()
    setLoading(false)
    }
    initiate()
},[])

  const handleDownload = async () => {
    setIsLoading(true);
    try {
        const res = await downloadReport();

        if (res.status === 200) {
            const blob = res.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        }
        else{
            notify("danger", "OOPS", "File not downloaded");
        }
    }
    catch (error) {
       notify("danger", "OOPS", error?.response?.data?.message);
    }
    setIsLoading(false);
}

const options = {
    series: series,
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    legend: {
      position: 'bottom',
    },
    labels: labels, // Use dynamically generated labels
    colors: ['#e6f2fa', '#002c47', '#b0d7ef', '#007dcc', '#818181', '#414141', '#a0f0a0', '#f0a0a0', '#f0f0a0', '#a0a0f0'], // Adjusted for more categories
    dataLabels: {
        enabled: false, // Disables the percentage inside the donut
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (value) => `${value}`, // Displays only the raw value in the tooltip
        },
      },
  };


// Extract data for each year range

useEffect(() => {
    let laptopIndex = dashboardData.findIndex(data => data?.asset_type === 'Laptop');
    let desktopIndex = dashboardData.findIndex(data => data?.asset_type === 'Desktop');
    // console.log("laptop index", laptopIndex);
    
    if (dashboardData.length >= 2) {
        // Extract data for each year range, adding checks to avoid undefined errors
        const laptopData = [
            dashboardData[laptopIndex]?.less_than_1 || 0,
            dashboardData[laptopIndex]?.between_1_2 || 0,
            dashboardData[laptopIndex]?.between_2_3 || 0,
            dashboardData[laptopIndex]?.between_3_4 || 0,
            dashboardData[laptopIndex]?.between_4_5 || 0,
            dashboardData[laptopIndex]?.between_5_6 || 0,
            dashboardData[laptopIndex]?.between_6_7 || 0,
            dashboardData[laptopIndex]?.between_7_8 || 0,
            dashboardData[laptopIndex]?.between_8_9 || 0,
        ];

        const desktopData = [
            dashboardData[desktopIndex]?.less_than_1 || 0,
            dashboardData[desktopIndex]?.between_1_2 || 0,
            dashboardData[desktopIndex]?.between_2_3 || 0,
            dashboardData[desktopIndex]?.between_3_4 || 0,
            dashboardData[desktopIndex]?.between_4_5 || 0,
            dashboardData[desktopIndex]?.between_5_6 || 0,
            dashboardData[desktopIndex]?.between_6_7 || 0,
            dashboardData[desktopIndex]?.between_7_8 || 0,
            dashboardData[desktopIndex]?.between_8_9 || 0,
        ];

        // Update chart options state
        setOptionsBar(prevOptions => ({
            ...prevOptions,
            series: [
                { name: 'Laptops', data: laptopData },
                { name: 'Desktops', data: desktopData }
            ]
        }));
    }
}, [dashboardData]);

  return (
    <>
    {loading ? 
     <div
     style={{
       display: "flex",
       justifyContent: "center",
       alignItems: "center",
     }}
   >
     <div class="loader">
       Loading
       <span></span>
     </div>
   </div>
    :
    <div className="px-3 py-4" style={{backgroundColor: "#FCFCFD"}}>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {userLoginDetails?.orgId == 1 ? <p
                        style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            marginBottom: "0px",
                        }}
                    >
                        Life Asset Management Dashboard
                    </p>
                    :
                    <p
                        style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            marginBottom: "0px",
                        }}
                    >
                        GI Asset Management Dashboard
                    </p>}
                    <p style={{ color: "#475467", fontSize: "18px" }}>
                    Welcome {capitalize(userLoginDetails?.firstName)},
                    </p>
                </div>
                <button
                    className="rounded py-2 px-3"
                    style={{
                        color: "white",
                        border: "none",
                        backgroundColor: "#007DCC",
                        cursor: "pointer"
                    }}
                    onClick={handleDownload}
                >
                    <FaArrowDownLong size={14} /> Download Report
                </button>
            </div>
            <div className='mt-4 d-flex flex-wrap' style={{gap: "30px"}}>
                <div style={{border: "1px solid #D0D5DD", borderRadius: "5px", backgroundColor: "white", padding: "20px", minWidth:"23%", maxHeight: "122px"}}>
                    <p style={{color: "#697586", fontSize: "16px", marginBottom: "10px"}}>Total Assets</p>
                    <p style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            marginBottom: "0px",
                        }}>{metricsRes?.data?.total_asset}</p>
                </div>
                <div style={{border: "1px solid #D0D5DD", borderRadius: "5px", backgroundColor: "white", padding: "20px", minWidth:"23%", maxHeight: "122px"}}>
                    <p style={{color: "#697586", fontSize: "16px", marginBottom: "10px"}}>Assigned Assets</p>
                    <p style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            marginBottom: "0px",
                        }}>{metricsRes?.data?.assigned}</p>
                </div>
                <div style={{border: "1px solid #D0D5DD", borderRadius: "5px", backgroundColor: "white", padding: "20px", minWidth:"23%", maxHeight: "122px"}}>
                    <p style={{color: "#697586", fontSize: "16px", marginBottom: "10px"}}>Active Assets</p>
                    <p style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            marginBottom: "0px",
                        }}>{metricsRes?.data?.active}</p>
                </div>
                <div style={{border: "1px solid #D0D5DD", borderRadius: "5px", backgroundColor: "white", padding: "20px", minWidth:"23%", maxHeight: "122px"}}>
                    <p style={{color: "#697586", fontSize: "16px", marginBottom: "10px"}}>Inactive Assets</p>
                    <p style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            marginBottom: "0px",
                        }}>{metricsRes?.data?.inactive}</p>
                </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
            <div className='mt-4' style={{border: "1px solid #D0D5DD", borderRadius: "5px", backgroundColor: "white", padding: "20px", width:"50%"}}>
                <p style={{marginBottom: "10px", fontWeight: "bold", fontSize: "20px"}}>PC Age Analysis</p>
            <Chart options={optionsBar} series={optionsBar.series} type="bar" height={350} />
            </div>
            <div style={{
      border: "1px solid #D0D5DD",
      borderRadius: "5px",
      backgroundColor: "white",
      padding: "20px",
      width: "45%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      position: "relative"
    }}>
        <p style={{marginBottom: "10px", fontWeight: "bold", fontSize: "20px"}}>Number of Assets</p>
        <div style={{position: "absolute", top: "140px"}}>
        <p style={{marginBottom: "0px", fontSize: "14px", textAlign: "center", color: "#697586"}}>Total</p>
        <p style={{marginBottom: "10px", fontWeight: "bold", fontSize: "26px"}}>{metricsRes?.data?.total_asset}</p>
        </div>
      <Chart options={options} series={options.series} type="donut" width="400px" />
    </div>
            </div>
            
            <LoadingModal show={isLoading} />
            </div>}
            </>
  )
}

export default Dashboard