import React from 'react'
import { Modal, Spinner } from 'react-bootstrap';

const InactiveAssetModal = ({ show, onHide, handleSubmit}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="">
        <form onSubmit={handleSubmit} className='d-flex justify-content-center align-items-center flex-column px-5'>
            <p style={{fontSize: "32px", fontWeight: "600", marginBottom: "0px"}}>Inactive Asset.</p>
            <p style={{color: "#475467", fontWeight: "500", fontSize: "18px", textAlign: "center"}}>Are you sure you want to mark asset as inactive?</p>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <button
                className="rounded py-2 px-3"
                type="button"
                style={{
                    color: "white",
                    border: "none",
                    backgroundColor: "#007DCC",
                    width: "150px"
                }}
                onClick={handleSubmit}>Yes</button>
                <button
                className="rounded py-2 px-3"
                type="button"
                style={{
                    color: "white",
                    border: "none",
                    backgroundColor: "red",
                    width: "150px"
                }}
                onClick={onHide}>No</button>
            </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default InactiveAssetModal