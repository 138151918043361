import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { capitalize, notify } from "../components/actions/CommonActions";
import LoadingModal from "../components/modals/LoadingModal";
import { all } from "axios";
import { useNavigate } from "react-router-dom";
import { FaArrowDownLong } from "react-icons/fa6";
import { downloadReport } from "../services";

const Reports = () => {

    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            const res = await downloadReport();
    
            if (res.status === 200) {
                const blob = res.data;
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'report.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            }
            else{
                notify("danger", "OOPS", "File not downloaded");
            }
        }
        catch (error) {
           notify("danger", "OOPS", error?.response?.data?.message);
        }
        setIsLoading(false);
    }

  return (
    <div className="px-3 py-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "32px",
                  marginBottom: "0px",
                }}
              >
                Reports
              </p>
              <p style={{ color: "#475467", fontSize: "18px" }}>
              View and manage reports by applying filters
              </p>
            </div>
            <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
                cursor: "pointer"
              }}
              onClick={handleDownload}
            >
              <FaArrowDownLong size={14} /> Export
            </button>
          </div>
          <div
            style={{
              borderRadius: "5px",
              marginTop: "30px",
            }}
          >
            <table style={{ width: "100%", overflowX: "scroll" }}>
            <thead>
                <tr
                  style={{
                    borderBottom: "0.5px solid #f1f1f1",
                    backgroundColor: "#F7F7F7",
                  }}
                >
                  {/* Table header cells */}
                  {/* Modify according to your data structure */}
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    S/N
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    TAG NO
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    LOCATION
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    STATUS
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    MAINTENANCE
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    AGE ANALYSIS
                  </th>
                  <th
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      color: "#818181",
                      fontWeight: "500",
                    }}
                  >
                    ASSET TYPE
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}></tbody>
            </table>
          </div>
          <LoadingModal show={isLoading} />
    </div>
  )
}

export default Reports