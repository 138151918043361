import moment from 'moment'
import React from 'react'
import { IoChevronDownSharp, IoChevronUpOutline } from 'react-icons/io5'
import { MdAdd } from 'react-icons/md'

const MaintenanceHistory = ({gottenData, setOpenMaintenanceHistoryModal, setHandoverAssetModal, handleSHowUserHistoryModal, userHistoryID, openUserHistoryModal}) => {
  return (
    <>
          <div style={{marginBottom: "0px", backgroundColor: "#F2F4F7", padding: "10px", display: "flex", justifyContent: "space-between"}}>
          <p style={{marginBottom: "0px", fontWeight: "500", fontSize: "23px"}}>Maintenance History</p>
          <button
              className="rounded py-2 px-3"
              style={{
                color: "#007DCC",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                fontWeight: "bold"
              }}
              onClick={() => setOpenMaintenanceHistoryModal(true)}
            >
              <MdAdd size={20} /> Add New
            </button>
          </div>
          <div style={{border: "1px solid #EAECF0", padding: "10px", minHeight: "60vh"}}>
          <div style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
            {gottenData?.service_history?.map ((each, index) => 
            <div key={index} 
            // onClick={()=> handleSHowUserHistoryModal(each?.id)}
             style={{border: "1px solid #EAECF0", padding: "20px", width: "400px", borderRadius: "5px", marginTop: "10px"}}>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <p style={{fontWeight: "500", marginBottom: "0px"}}>{each?.issueFixed}</p>
                {/* {openUserHistoryModal && each?.id == userHistoryID ? <IoChevronUpOutline /> : <IoChevronDownSharp />} */}
              </div>
             {/* {each?.id == userHistoryID && openUserHistoryModal &&  */}
             <>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", marginTop: "15px"}}>
              <p style={{ marginBottom: "0px"}}>Issue fixed</p>
              <p style={{ marginBottom: "0px", fontWeight: "500"}}>{each?.issueFixed}</p>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
              <p style={{ marginBottom: "0px"}}>Fixed by </p>
              <p style={{ marginBottom: "0px", fontWeight: "500"}}>{each?.fixedBy}</p>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
              <p style={{ marginBottom: "0px"}}>Person in charge </p>
              <p style={{ marginBottom: "0px", fontWeight: "500"}}>{each?.personInCharge}</p>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
              <p style={{ marginBottom: "0px"}}>Cost</p>
              <p style={{ marginBottom: "0px", fontWeight: "500"}}>₦{each?.extraInformation?.cost}</p>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
              <p style={{ marginBottom: "0px"}}>Date fixed </p>
              <p style={{ marginBottom: "0px", fontWeight: "500"}}>{moment(each?.dateFixed).format("MM/DD/YYYY")}</p>
                </div>
                {each?.extraInformation?.reason && <div style={{padding: "10px", backgroundColor: "#f2f4f7", borderRadius: "5px"}}>
                  <p style={{color: "#98A2B3", fontSize: "14px", marginBottom: "0px"}}>Reason</p>
                  <p>{each?.extraInformation?.reason}</p>
                </div>}
                </>
                {/* } */}
            </div>)}
                </div>
          </div>
          </>
  )
}

export default MaintenanceHistory