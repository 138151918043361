import React from 'react'
import { Modal, Spinner } from 'react-bootstrap';

const AddLocation = ({ show, onHide, handleChange, handleSubmit}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="">
        <form onSubmit={handleSubmit} className='d-flex justify-content-center align-items-center flex-column px-5'>
            <p style={{fontSize: "32px", fontWeight: "600", marginBottom: "0px"}}>Add location.</p>
            <p style={{color: "#475467", fontWeight: "500"}}>To add location kindly fill the form below.</p>

        <div className="d-flex align-items-center flex-column  mt-2" style={{ width: "100%" }}>
          <div className="d-flex flex-column justify-content-start">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>Location</p>
            <input
              type="text"
              name="location"
              required
              placeholder='Marina'
              onChange={handleChange}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            />
          </div>

        </div>

            <div className='d-flex justify-content-between mt-4' style={{width: "100%"}}>
             <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "red",
              }}
              onClick={onHide}
            >
              Close
            </button>
            <div></div>
            <button
            type="submit"
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
              }}
            >
              Submit
            </button>
            </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddLocation