import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { capitalize, notify } from "../components/actions/CommonActions";
import LoadingModal from "../components/modals/LoadingModal";
import { all } from "axios";
import { useNavigate } from "react-router-dom";
import { FaArrowDownLong } from "react-icons/fa6";
import { createLocationApi, createUser, deleteUserApi, downloadReport, getAllOrganizations, getAllUsers, inActiveUserApi } from "../services";
import { FiPlus } from "react-icons/fi";
import AddUser from "../components/modals/AddUser";
import { HiDotsVertical } from "react-icons/hi";
import InactiveAssetModal from "../components/modals/InactiveAssetModal";
import InactiveUserModal from "../components/modals/InactiveUserModal";
import DeleteUserModal from "../components/modals/DeleteUserModal";
import { IoLocationOutline } from "react-icons/io5";
import AddLocation from "../components/modals/AddLocation";

const Users = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [addLocationModal, setAddLocationModal] = useState(false);
    const [userData, setUserData] = useState(null)
    const [userLoginDetails, setUserLoginDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const [allUsers, setAllUsers] = useState(null)
    const [indexPressed, setIndexPressed] = useState(null);
  const [show, setShow] = useState(false);
  const [inactiveModal, setInactiveModal] = useState(false);
  const [inactivateId, setInactivateId] = useState(null);
  const [delUser, setDelUser] = useState(false);
  const [allOrganizations, setAllOrganizations] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

//   console.log("all organizations ", allOrganizations);
  

    const getUsersOnPlatform = async () => {
        setLoading(true)
        await getAllUsers()
            .then((res) => {
                console.log("all users ", res);
                setAllUsers(res?.data)
            })
            .catch((err) => {
                notify("danger", "OOPS", err?.response?.data?.message);
            })
        setLoading(false)
    }

    const AllOrganizations = async (page, limit) => {
        setIsLoading(true);
        await getAllOrganizations()
          .then(async (res) => {
            // console.log("organizations res ", res);
            setAllOrganizations(res?.data);
            // setAllUsers(res)
            // notify("success", "Profile updated", res?.message);
          })
          .catch((err) => {
            // console.log('err res anon ', err);
            notify("danger", "OOPS", err?.response?.data?.message);
          });
        setIsLoading(false);
      };

    useEffect(() => {
        const initiate = async () => {
            const getUserdata = await localStorage.getItem("userdata")
            setUserLoginDetails(JSON.parse(getUserdata));
            getUsersOnPlatform()
            AllOrganizations()
        }
        initiate()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setUserData((prev) => ({ ...prev, [name]: value }))
    }

    const handleChangeLocation = (e) => {
      const { name, value } = e.target;
      
      setUserLocation((prev) => ({ ...prev, [name]: value }))
  }

    const handleSubmit = async (e) => {
        e.preventDefault()
        userData.org_id = Number(selectedOrg)
        // console.log("user data ", userData);
        
        setAddUserModal(false)
        setIsLoading(true)
        await createUser(userData)
            .then(async (res) => {
                setIsLoading(false);
                //   console.log("create user res ", res);
                setUserData(null)
                notify("success", "User created", res?.message);
                getUsersOnPlatform();
            })
            .catch((err) => {
                notify("danger", "OOPS", err?.response?.data?.message);
            });

        setIsLoading(false);
    }

    const handleInactiveAsset = async() =>{
        setInactiveModal(false)
        setIsLoading(true);
        await inActiveUserApi(inactivateId)
        .then(async (res)=>{
          // console.log("search res ", res);
          notify("success", "Successful", "User marked as inactive");
          getUsersOnPlatform();
        })
        .catch((err)=>{
          console.log("search err ", err);notify("danger", "OOPS", err?.response?.data?.message);
          
        })
        setIsLoading(false);
       }

       const handleDeleteUser = async() =>{
        setDelUser(false)
        setIsLoading(true);
        await deleteUserApi(inactivateId)
        .then(async (res)=>{
          // console.log("search res ", res);
          notify("success", "Successful", "User marked as inactive");
          getUsersOnPlatform();
        })
        .catch((err)=>{
          console.log("search err ", err);notify("danger", "OOPS", err?.response?.data?.message);
          
        })
        setIsLoading(false);
       }

       const handleSubmitLocation = async (e) => {
        e.preventDefault()
        // console.log("user data ", userData);
        
        setAddLocationModal(false)
        setIsLoading(true)
        await createLocationApi(userLocation)
            .then(async (res) => {
                setIsLoading(false);
                //   console.log("create user res ", res);
                setUserLocation(null)
                notify("success", "Location created", res?.message);
            })
            .catch((err) => {
                notify("danger", "OOPS", err?.response?.data?.message);
            });

        setIsLoading(false);
    }



    return (<>
    {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="loader">
            Loading
            <span></span>
          </div>
        </div>
      ) : (
        <div className="px-3 py-4">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <p
                        style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            marginBottom: "0px",
                        }}
                    >
                        Users
                    </p>
                    <p style={{ color: "#475467", fontSize: "18px" }}>
                        Create and deactivate users
                    </p>
                </div>
                <div style={{display: "flex"}}>
                      <button
                      className="rounded py-2 px-3"
                      style={{
                        border: "1px solid #007DCC",
                        color: "#007DCC",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        marginRight: "10px"
                      }}
                      onClick={() => setAddLocationModal(true)}
                      >
                      <IoLocationOutline size={20} /> Add location
                      </button>
                <button
                    className="rounded py-2 px-3"
                    style={{
                        color: "white",
                        border: "none",
                        backgroundColor: "#007DCC",
                        cursor: "pointer"
                    }}
                    onClick={() => setAddUserModal(true)}
                >
                    <FiPlus size={20} /> Add user
                </button>
                </div>
            </div>
            <div
                style={{
                    borderRadius: "5px",
                    marginTop: "30px",
                }}
            >
                <table style={{ width: "100%", overflowX: "scroll" }}>
                    <thead>
                        <tr
                            style={{
                                borderBottom: "0.5px solid #f1f1f1",
                                backgroundColor: "#F7F7F7",
                            }}
                        >
                            {/* Table header cells */}
                            {/* Modify according to your data structure */}
                            <th
                                style={{
                                    padding: "15px",
                                    fontSize: "18px",
                                    color: "#818181",
                                    fontWeight: "500",
                                }}
                            >
                                S/N
                            </th>
                            <th
                                style={{
                                    padding: "15px",
                                    fontSize: "18px",
                                    color: "#818181",
                                    fontWeight: "500",
                                }}
                            >
                                FIRST NAME
                            </th>
                            <th
                                style={{
                                    padding: "15px",
                                    fontSize: "18px",
                                    color: "#818181",
                                    fontWeight: "500",
                                }}
                            >
                                LAST NAME
                            </th>
                            <th
                                style={{
                                    padding: "15px",
                                    fontSize: "18px",
                                    color: "#818181",
                                    fontWeight: "500",
                                }}
                            >
                                EMAIL
                            </th>
                            <th
                                style={{
                                    padding: "15px",
                                    fontSize: "18px",
                                    color: "#818181",
                                    fontWeight: "500",
                                }}
                            >
                                ORGANIZATION
                            </th>
                            <th
                                style={{
                                    padding: "15px",
                                    fontSize: "18px",
                                    color: "#818181",
                                    fontWeight: "500",
                                }}
                            >
                                STATUS
                            </th>
                            <th
                                style={{
                                    padding: "15px",
                                    fontSize: "18px",
                                    color: "#818181",
                                    fontWeight: "500",
                                }}
                            ></th>
                        </tr>
                    </thead>
                    <tbody style={{ width: "100%" }}>
                        {allUsers?.map((item, index) => {

                            return (
                                <tr
                                key={index}
                                style={{ backgroundColor: "white", width: "100%" }}
                              >
                                <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {item?.first_name}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {item?.last_name}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {item?.email}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {item?.organization?.name}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {item?.status}
                      </td>
                      <td
                        style={{ position: "relative" }}
                        onClick={() => {
                          setIndexPressed(item?.id)
                          setShow(!show);
                        }}
                      >
                        <span
                          style={{
                            border: "1px solid #E4E7EC",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            zIndex: "5",
                          }}
                        >
                          <HiDotsVertical />
                        </span>
                        {item?.id == indexPressed && show && <div style={{position: "absolute", padding: "5px 10px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", zIndex: "10", backgroundColor: "white", left: "-100px", width: "150px"}}>
                         <p onClick={()=> {
                          setInactiveModal(true)
                          setInactivateId(item?.id)
                         }} style={{fontSize: "14px", padding: "5px", cursor: "pointer", textAlign: "center", marginBottom: "0px"}}>Mark as inactive</p>
                         <p onClick={()=> {
                          setDelUser(true)
                          setInactivateId(item?.id)
                         }} style={{fontSize: "14px", padding: "5px", cursor: "pointer", textAlign: "center", marginBottom: "0px"}}>Delete user</p>
                      </div>}
                     
                      </td>
                              </tr>
                                    )
                        })}

                    </tbody>
                </table>
            </div>
            <LoadingModal show={isLoading} />
            <AddUser show={addUserModal} setSelectedOrg={setSelectedOrg} onHide={() => setAddUserModal(false)} allOrganizations={allOrganizations} handleChange={handleChange} handleSubmit={handleSubmit} />
            <InactiveUserModal show={inactiveModal} onHide={()=> setInactiveModal(false)} handleSubmit={handleInactiveAsset} />
            <DeleteUserModal show={delUser}  onHide={()=> setDelUser(false)} handleSubmit={handleDeleteUser} />
              <AddLocation show={addLocationModal} onHide={()=> setAddLocationModal(false)} handleChange={handleChangeLocation} handleSubmit={handleSubmitLocation} />
        </div>
      )
                    }
        </>
    )
}

export default Users