import React, { useEffect, useState } from 'react'
import { GoPencil } from 'react-icons/go'
import { addAttachmentAsset, addMaintenanceAsset, deleteAttachmentAsset, editAsset, getAllAssetsType, getLocation, getSingleAsset, handoverAsset, reassignAsset, uploadAttachmentEndpoint } from '../services';
import { useParams } from 'react-router-dom';
import { capitalize, notify } from '../components/actions/CommonActions';
import { FiPlus } from 'react-icons/fi';
import EditPurchaseDetails from '../components/modals/EditPurchaseDetails';
import { IoChevronDownSharp, IoChevronUpOutline } from 'react-icons/io5';
import moment from 'moment';
import ReassignAssetModal from '../components/modals/ReassignAssetModal';
import HandoverAssetModal from '../components/modals/handoverAsset';
import AssetInfo from '../components/AssetInfo';
import PurchaseInfo from '../components/PurchaseInfo';
import UserHistory from '../components/UserHistory';
import MaintenanceHistory from '../components/MaintenanceHistory';
import AddMaintenanceHistory from '../components/modals/AddMaintenanceHistory';
import AttachmentAsset from '../components/Attachments';
import LoadingModal from '../components/modals/LoadingModal';
import EditHistory from '../components/EditHistory';

const AssetDetails = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [gottenData, setGottenData] = useState(null);
  const [allAssetsType, setAllAssetsType] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [arrayData, setArrayData] = useState(null)
  const [openUserHistoryModal, setOpenUserHistoryModal] = useState(false);
  const [reassignAssetModal, setReassignAssetModal] = useState(false)
  const [dataReassign, setDataReassign] = useState(null)
  const [userHistoryID, setUserHistoryID] = useState(null)
  const [handoverAssetModal, setHandoverAssetModal] = useState(false)
  const [dataHandover, setDataHandover] = useState(null)
  const [openMaintenanceHistoryModal, setOpenMaintenanceHistoryModal] = useState(false)
  const [dataMaintenanceHistory, setDataMaintenanceHistory] = useState(null)
  const [isLoggingin, setIsLoggingIn] = useState(false);
  const [allUserLocations, setAllUserLocation] = useState(null);

  const { id } = useParams();
  const handleViewDetails = async (id) => {
    await getSingleAsset(id)
      .then(async (res) => {
        // console.log("single asset res ", res);
        setGottenData(res?.data);
        setArrayData(Object.entries(res?.data?.metadata?.dynamicFields || {}).map(([key, value]) => ({ key, value })));
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
  }

  const AllAssetsType = async (page, limit) => {
    await getAllAssetsType()
      .then(async (res) => {
        // console.log("assets res ", res);
        setAllAssetsType(res);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
  };

  const AllLocations = async (page, limit) => {
    // setIsLoading(true);
    await getLocation()
      .then(async (res) => {
        console.log("organizations res ", res);
        setAllUserLocation(res?.data);
        // setAllUsers(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    // setIsLoading(false);
  };

  useEffect(() => {
    const AllNeededData = async () => {
      setLoading(true);
      await handleViewDetails(id);
      await AllAssetsType();
      AllLocations()
      setLoading(false);
    }
    AllNeededData()
  }, [])

  const onHide = () => {
    setEditModalShow(false)
  }

  const onHideReassignAssetModal = async () => {
    setReassignAssetModal(false)
  }

  const onHideHandoverAssetModal = async () => {
    setHandoverAssetModal(false)
  }

  const onHideMaintenanceHistoryModal = async () => {
    setOpenMaintenanceHistoryModal(false)
  }

  const handleChange = (index, field, newValue) => {
    setArrayData(prevFields =>
      prevFields.map((item, i) =>
        i === index ? { ...item, [field]: newValue } : item
      )
    );
  };

  const handleEditPurchase = async () => {
    const dynamicFieldsObject = arrayData.reduce((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {});
    console.log("Converted Object:", dynamicFieldsObject);
    let sentData = {
      asset_id: gottenData?.id,
      description: gottenData?.description,
      asset_tag: gottenData?.assetTag
    };
    const newData = { ...dynamicFieldsObject, ...sentData };
    console.log("new data ", newData);
    setEditModalShow(false);
    setLoading(true);
    await editAsset(newData)
      .then(async (res) => {
        console.log("single asset res ", res);
        notify("success", "Purchase details updated.", res?.message);
        await handleViewDetails(id);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setLoading(false);

    // Submit logic here
  };

  const handleSHowUserHistoryModal = (id) => {
    setOpenUserHistoryModal(!openUserHistoryModal);
    setUserHistoryID(id)
  }

  const handleChangeReassignAsset = async (e) => {
    const { name, value } = e.target
    setDataReassign((prev) => ({ ...prev, [name]: value }))
  }

  const handleChangeHandoverAsset = async (e) => {
    const { name, value } = e.target
    setDataHandover((prev) => ({ ...prev, [name]: value }))
  }

  const handleChangeMaintenanceHistory = async (e) => {
    const { name, value } = e.target
    setDataMaintenanceHistory((prev) => ({ ...prev, [name]: value }))
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log("file ", file);


    // Check if a file is selected
    if (!file) return;

    // File size validation: 5MB = 5 * 1024 * 1024 bytes
    if (file.size > 5 * 1024 * 1024) {
      alert('File size should not exceed 5MB');
      return;
    }

    // Prepare formData
    const formData = new FormData();
    formData.append('file', file);

    setIsLoggingIn(true);
    await uploadAttachmentEndpoint(formData)
      .then(async (res) => {
        // console.log("upload attachment res ", res);
        let sentData = {
          asset_id: gottenData?.id,
          attachment_url: res?.data?.url,
          name: file?.name
        }
        console.log("sent data ", sentData);
        
        await addAttachmentAsset(sentData)
          .then(async (res) => {
            // console.log("upload attachment res ", res);
            notify("success", "Attachment uploaded.", res?.message);
            await handleViewDetails(id);
          })
          .catch((err) => {
            // console.log('err res anon ', err);
            notify("danger", "OOPS", err?.response?.data?.message);
          });
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setIsLoggingIn(false);
  };

  const handleDeleteAttachment = async (attachment_id) => {
    setIsLoggingIn(true);
    await deleteAttachmentAsset(attachment_id)
          .then(async (res) => {
            console.log("deleted attachment res ", res);
            notify("success", "Attachment deleted.", res?.message);
            await handleViewDetails(id);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setIsLoggingIn(false);
  }


  const handleSubmitReassignAsset = async (e) => {
    e.preventDefault()
    dataReassign.asset_id = gottenData?.id
    // console.log("reassign user submit ", dataReassign);
    setReassignAssetModal(false);
    setLoading(true);
    await reassignAsset(dataReassign)
      .then(async (res) => {
        // console.log("reassign asset res ", res);
        notify("success", "Asset reassigned.", res?.message);
        await handleViewDetails(id);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setLoading(false);
  }

  const handleSubmitHandoverAsset = async (e) => {
    e.preventDefault()
    dataHandover.asset_id = gottenData?.id
    // console.log("reassign user submit ", dataReassign);
    setHandoverAssetModal(false);
    setLoading(true);
    await handoverAsset(dataHandover)
      .then(async (res) => {
        // console.log("reassign asset res ", res);
        notify("success", "Handover successful.", res?.message);
        await handleViewDetails(id);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setLoading(false);
  }


  const handleSubmitMaintenanceHistory = async (e) => {
    e.preventDefault()
    dataMaintenanceHistory.assetId = gottenData?.id
    // console.log("reassign user submit ", dataReassign);
    setOpenMaintenanceHistoryModal(false);
    setLoading(true);
    await addMaintenanceAsset(dataMaintenanceHistory)
      .then(async (res) => {
        // console.log("maintenance asset res ", res);
        notify("success", "Maintenance record added.", res?.message);
        await handleViewDetails(id);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setLoading(false);
  }



  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="loader">
            Loading
            <span></span>
          </div>
        </div>
      ) : (
        <div className="px-3 py-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "32px",
                  marginBottom: "0px",
                }}
              >
                {capitalize(gottenData?.description)}
              </p>
            </div>
            {/* <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
              }}
            //   onClick={() => setAddAssetModal(true)}
            >
              <GoPencil size={20} /> Edit asset
            </button> */}
          </div>
          <div className='my-4 d-flex'>
            <div onClick={() => setStep(1)} style={{ padding: "10px", backgroundColor: step == 1 ? "#007DCC" : "transparent", color: step == 1 ? "white" : "black", border: step == 1 ? "1px solid #007DCC" : "1px solid #E4E4E7", cursor: "pointer" }}>
              Asset Info
            </div>
            {/* <div onClick={() => setStep(2)} style={{ padding: "10px", backgroundColor: step == 2 ? "#007DCC" : "transparent", color: step == 2 ? "white" : "black", border: step == 2 ? "1px solid #007DCC" : "1px solid #E4E4E7", cursor: "pointer" }}>
              Purchase Info
            </div> */}
            <div onClick={() => setStep(3)} style={{ padding: "10px", backgroundColor: step == 3 ? "#007DCC" : "transparent", color: step == 3 ? "white" : "black", border: step == 3 ? "1px solid #007DCC" : "1px solid #E4E4E7", cursor: "pointer" }}>
              User History
            </div>
            <div onClick={() => setStep(4)} style={{ padding: "10px", backgroundColor: step == 4 ? "#007DCC" : "transparent", color: step == 4 ? "white" : "black", border: step == 4 ? "1px solid #007DCC" : "1px solid #E4E4E7", cursor: "pointer" }}>
              Maintenance History
            </div>
            <div onClick={() => setStep(5)} style={{ padding: "10px", backgroundColor: step == 5 ? "#007DCC" : "transparent", color: step == 5 ? "white" : "black", border: step == 5 ? "1px solid #007DCC" : "1px solid #E4E4E7", cursor: "pointer" }}>
              Attachments
            </div>
            <div onClick={() => setStep(6)} style={{ padding: "10px", backgroundColor: step == 6 ? "#007DCC" : "transparent", color: step == 6 ? "white" : "black", border: step == 6 ? "1px solid #007DCC" : "1px solid #E4E4E7", cursor: "pointer" }}>
              Edit History
            </div>
          </div>
          {step == 1 &&
            <AssetInfo gottenData={gottenData} setEditModalShow={setEditModalShow} />
          }
          {/* {step == 2 &&
            <PurchaseInfo gottenData={gottenData} setEditModalShow={setEditModalShow} />
          } */}
          {step == 3 &&
            <UserHistory gottenData={gottenData} setReassignAssetModal={setReassignAssetModal} setHandoverAssetModal={setHandoverAssetModal} handleSHowUserHistoryModal={handleSHowUserHistoryModal} userHistoryID={userHistoryID} openUserHistoryModal={openUserHistoryModal} />
          }
          {step == 4 &&
            <MaintenanceHistory gottenData={gottenData} setOpenMaintenanceHistoryModal={setOpenMaintenanceHistoryModal} handleSHowUserHistoryModal={handleSHowUserHistoryModal} userHistoryID={userHistoryID} openUserHistoryModal={openUserHistoryModal} />
          }
          {step == 5 &&
            <AttachmentAsset gottenData={gottenData} handleFileChange={handleFileChange} handleDeleteAttachment={handleDeleteAttachment} />
          }
          {step == 6 &&
            <EditHistory gottenData={gottenData} />
          }
          <EditPurchaseDetails show={editModalShow} onHide={onHide} gottenData={gottenData} arrayData={arrayData} handleChange={handleChange} handleEditPurchase={handleEditPurchase} />
          <ReassignAssetModal show={reassignAssetModal} allUserLocations={allUserLocations} onHide={onHideReassignAssetModal} handleChange={handleChangeReassignAsset} handleSubmit={handleSubmitReassignAsset} />
          <HandoverAssetModal show={handoverAssetModal} onHide={onHideHandoverAssetModal} handleChange={handleChangeHandoverAsset} handleSubmit={handleSubmitHandoverAsset} />
          <AddMaintenanceHistory show={openMaintenanceHistoryModal} onHide={onHideMaintenanceHistoryModal} handleChange={handleChangeMaintenanceHistory} handleSubmit={handleSubmitMaintenanceHistory} />
          <LoadingModal show={isLoggingin} />
        </div>
      )}

    </>
  )
}

export default AssetDetails