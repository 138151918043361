import React from 'react'
import { Modal, Spinner } from 'react-bootstrap';

const AddUser = ({ show, setSelectedOrg, onHide, handleChange, handleSubmit, allOrganizations}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="">
        <form onSubmit={handleSubmit} className='d-flex justify-content-center align-items-center flex-column px-5'>
            <p style={{fontSize: "32px", fontWeight: "600", marginBottom: "0px"}}>Add user.</p>
            <p style={{color: "#475467", fontWeight: "500"}}>To add user kindly fill the form below.</p>

        <div className="d-flex align-items-center flex-column  mt-2" style={{ width: "100%" }}>
          <div className="d-flex flex-column justify-content-start">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>Email</p>
            <input
              type="text"
              name="email"
              required
              placeholder='tobi.owoade@sanlam.com.ng'
              onChange={handleChange}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            />
          </div>

          <div className='d-flex flex-column justify-content-start mt-2'>
                <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left"}}>Organization <span style={{color: "#E02D3C"}}>*</span></p>
                <select required name='org_id' onChange={(e)=> setSelectedOrg(e.target.value)} style={{border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px"}}>
                    <option></option>
                    {allOrganizations?.map((each, index) => (
                <option key={index} value={each?.id}>
                  {each?.name}
                </option>
              ))}
                </select>
                </div>
          
          <div className="d-flex flex-column justify-content-start mt-2">
            <p style={{ marginBottom: "0px", fontWeight: "500", textAlign: "left" }}>Role</p>
            <select
              name="role"
              required
              onChange={handleChange}
              style={{ border: "1px solid #D0D5DD", padding: "10px", borderRadius: "5px", width: "450px" }}
            >
                <option value="">Select role</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
            </select>
            </div>
        </div>

            <div className='d-flex justify-content-between mt-4' style={{width: "100%"}}>
             <button
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "red",
              }}
              onClick={onHide}
            >
              Close
            </button>
            <div></div>
            <button
            type="submit"
              className="rounded py-2 px-3"
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#007DCC",
              }}
            >
              Submit
            </button>
            </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddUser