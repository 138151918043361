import axios from "axios";
import { notify } from "../components/actions/CommonActions";
// import { baseurl } from "../config/config";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
console.log("process.env.REACT_APP_BASE_URL : ", process.env.REACT_APP_BASE_URL);

// axios.defaults.timeout = process.env.REACT_APP_TIMEOUT;
//axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post["Accept"] = "application/json";
//axios.defaults.headers.common.accept = 'application/json';

axios.interceptors.request.use(
  async (config) => {
    const data = localStorage.getItem("token") || null;
    if (data) {
      // let parsedData = JSON.parse(data);
      // const token = parsedData.token;
      config.headers["Authorization"] = `Bearer ${data}`;
    }
    return config;
  },
  (error) => {
    console.log("This is an error: ", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the request was unauthorized and has not already been retried
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark request as retried

      try {
        const accessToken = await refreshToken(); // Attempt to refresh token
        // sessionStorage.setItem("token");
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`; // Update the access token in the header

        // Retry the original request with the new token
        return axios(originalRequest);
      } catch (refreshError) {
        console.log(refreshError);
        // If token refresh fails, handle accordingly (e.g., redirect to login)
        notify("danger", "Session expired", "Please login again.");
        localStorage.clear(); // Optionally clear session storage
        window.location.href = "/"; // Redirect to login page
        return Promise.reject(refreshError);
      }
    }

    // Return any other errors
    return Promise.reject(error);
  }
);

// User nibbs

async function refreshToken() {
  // Assuming you store refreshToken somewhere in storage
  const refreshToken = localStorage.getItem("refreshToken");
  const response = await axios.post("/user/refresh-token", { refreshToken });
  // console.log("this is token response: ", response);
  const accessToken = response.data.accessToken;

  // Store the new token
  localStorage.setItem("token", accessToken);

  return accessToken;
}

export async function loginUserApi(values) {
  const { data } = await axios.post(
    `/auth/sign-in`, values
  );

  return data;
}

export async function setPasswordUserApi(values, id) {
    const { data } = await axios.post(
      `/auth/set-password?token=${id}`, values
    );
  
    return data;
  }

  export async function getAllOrganizations() {
    const { data } = await axios.get(
      `/org`
    );
  
    return data;
  }

  export async function getAllAssetsType() {
    const { data } = await axios.get(
      `/asset-type`
    );
  
    return data;
  }

  export async function getAllAssets(limit, page, filter) {
    const { data } = await axios.get(
      `/asset/get-filter?limit=${limit}&page=${page}&filter=${filter}`
    );
  
    return data;
  }

  export async function getSingleAsset(id) {
    const { data } = await axios.get(
      `/asset/get-single?asset_id=${id}`
    );
  
    return data;
  }

  export async function addAsset(values) {
    const { data } = await axios.post(
      `/asset/add`, values
    );
  
    return data;
  }

  export async function editAsset(values) {
    const { data } = await axios.post(
      `/asset/edit`, values
    );
  
    return data;
  }

  export async function reassignAsset(values) {
    const { data } = await axios.post(
      `/asset/assign`, values
    );
  
    return data;
  }

  export async function handoverAsset(values) {
    const { data } = await axios.post(
      `/handover`, values
    );
  
    return data;
  }

  export async function addMaintenanceAsset(values) {
    const { data } = await axios.post(
      `/service/add`, values
    );
  
    return data;
  }

  export async function uploadAttachmentEndpoint(values) {
    const { data } = await axios.post(
      `/upload`, values, {
        'Content-Type': 'multipart/form-data'
      }
    );
  
    return data;
  }

  export async function addAttachmentAsset(values) {
    const { data } = await axios.post(
      `/attach/`, values
    );
  
    return data;
  }


  export async function deleteAttachmentAsset(values) {
    const { data } = await axios.delete(
      `/attach/del?attachment_url_id=${values}`
    );
   
    return data;
  }


  export async function downloadReport() {
    const res = await axios.get(
      `/report`,
      { responseType: 'blob' }
    );
   
    return res;
  }

  export async function createUser(values) {
    const { data } = await axios.post(
      `/auth/create-user`, values
    );
  
    return data;
  }

  export async function getAllUsers() {
    const {data} = await axios.get(
      `/auth/user-all`
    );
   
    return data;
  }

  export async function forgotPassword(values) {
    const { data } = await axios.post(
      `/auth/request-otp`, values
    );
  
    return data;
  }

  export async function validateOtpApi(values) {
    const { data } = await axios.post(
      `/auth/validate-otp`, values
    );
  
    return data;
  }

  export async function changePasswordApi(values) {
    const { data } = await axios.post(
      `/auth/change-password`, values
    );
  
    return data;
  }

  export async function getLocationApi(values) {
    const { data } = await axios.post(
      `/location`, values
    );
  
    return data;
  }

  export async function getDashboardMetrics() {
    const { data } = await axios.get(
      `/metric`
    );
  
    return data;
  }

  export async function searchAssetApi(search) {
    const { data } = await axios.get(
      `/asset/search?search_term=${search}`
    );
  
    return data;
  }

  export async function inActiveAssetApi(search) {
    const { data } = await axios.get(
      `/asset/status-inactive?asset_id=${search}`
    );
  
    return data;
  }

  export async function inActiveUserApi(search) {
    const { data } = await axios.get(
      `/auth/status/inactive?user_id=${search}`
    );
  
    return data;
  }

  export async function deleteUserApi(search) {
    const { data } = await axios.delete(
      `/auth/delete?user_id=${search}`
    );
  
    return data;
  }

  export async function getLocation() {
    const { data } = await axios.get(
      `/location`
    );
  
    return data;
  }

  export async function getAllCountApi() {
    const { data } = await axios.get(
      `/asset/all-count`
    );
  
    return data;
  }

  export async function createLocationApi(values) {
    const { data } = await axios.post(
      `/location/add`, values
    );
  
    return data;
  }