import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import pcFrame from '../assets/pcFrame.png'
import line from '../assets/linepattern.png'
import lineImage from '../assets/Line.png'
import logo from '../assets/logo_sanlam.png'
import LoginImage from '../assets/LoginImage.png'
import { FaEye, FaEyeSlash, FaSpinner } from 'react-icons/fa';
import { notify } from '../components/actions/CommonActions';
import { Button, Col, Form, Row } from 'react-bootstrap';
import LoadingModal from '../components/modals/LoadingModal';
import { forgotPassword, loginUserApi, validateOtpApi } from '../services';

const ValidateOtp = () => {
const [login, setLogin] = useState({ otp: ""});
  const [isLoggingin, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  async function loginAcc() {
    setIsLoggingIn(true);
    try {
        const userEmail = await localStorage.getItem("userEmail")
        login.email = userEmail
        const result = await validateOtpApi(login);
        
          await localStorage.setItem("forgotPasswordSessionId", result?.session_id)
          setIsLoggingIn(false);
          navigate("/change-password");
        // navigate("/dashboard")
    } catch (error) {
      notify("danger", "OOPS", error?.response?.data?.message);
    }
    setIsLoggingIn(false);
  }

  return (
    <Row style={{ height: "100vh" }}>
      <Col
        xs={6}
        style={{
          backgroundColor: "#007dcc",
          position: "relative",
          color: "#fff",
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'cover'
        }}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
      </Col>
      <Col
        xs={6}
        className="d-flex align-items-center gap-5 justify-content-center flex-column"
      >
        <div style={{ width: "65%", marginBottom: "150px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "150px", marginBottom: "120px" }}
          />
          <h3>Validate Otp</h3>
          <p className="text-muted mb-4" style={{ fontSize: "0.8rem" }}>
            Enter the otp sent to your mail.
          </p>
          <Form className="d-flex flex-column gap-3" onSubmit={loginAcc}>
            <Form.Group>
              <Form.Label>OTP</Form.Label>
              <Form.Control name="otp" onChange={handleLoginChange} />
            </Form.Group>
            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#007dcc" }}
              disabled={isLoggingin}
              onClick={(e)=>{
                e.preventDefault()
                loginAcc()
              }}
            >
              {isLoggingin ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </div>
      </Col>
      <LoadingModal show={isLoggingin} />
    </Row>
  );
};

export default ValidateOtp