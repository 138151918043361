import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const LoadingModal = ({ show, onHide, message }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div className="mt-3">{message || 'Loading, please wait...'}</div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
