import * as React from 'react';

export const initialState = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    pageTitle: process.env.REACT_APP_TITLE,
    userData: null,
    userToken: null,
    userTokenExp: null,
    userRouteSet: false,
    appIsReady: false,
    formValues: {},
    modalTitle: '',
    formActionType: 1,
    stateData: null,
    currentPath: '',
    showPageTitle: true,
    userTypes: [],
};

export const AppReducer = (initialState, action) => {
    switch (action.type) {
        case "APP_READY": {
            return {
                ...initialState,
                appIsReady: true,
                userToken: action.userToken,
                userData: action.userData,
                userTokenExp: action.userTokenExp,
                userRouteSet: action.userRouteSet || false,
                userTypes: action.userTypes,
            };
        }
        case "LOGIN": {
            return {
                ...initialState,
                userToken: action.userToken,
                userData: action.userData,
                userTypes: action.userTypes,
            };
        }
        case "LOGOUT":
            return {
                ...initialState,
                userToken: null,
                userData: null,
                userTokenExp: null,
                userRouteSet: false,
            };
        case "SET_ROUTE":
            return {
                ...initialState,
                userRouteSet: action.userRouteSet,
            };
        case "UPDATE_FORM":
            const name = action.name;
            const value = action.value;
            let { formValues } = initialState;
            formValues = { ...formValues, [name]: value };
            return {
                ...initialState,
                formValues
            };
        case "CLEAR_FORM":
            return {
                ...initialState,
                formValues: {}
            };
        case "SET_STATE":
            const stateName = action.name;
            const stateValue = action.value;
            return {
                ...initialState,
                [stateName]: stateValue
            };
        case "SET_PAGE_TITLE":
            return {
                ...initialState,
                pageTitle: action.title,
            };
        case "SET_PATH_NAME":
            return {
                ...initialState,
                currentPath: action.pathname,
            };
        case "SHOW_PAGE_TITLE":
            return {
                ...initialState,
                showPageTitle: action.showPageTitle,
            };
        default:
            return initialState;
    }
};